<script>
import Layout from '@layouts/main'

import { setComputed } from '@state/helpers'

import { getScrollTop, scrollTo } from '@utils/scroll'

export default {
  components: { Layout},
  data() {
    return {
      scrollEnd: null,
      animateStart: false,
      text: {
        'zh-TW': {
          slogan: [
            "<span>饗</span><span>．</span><span>樂</span>", "與您最愛的家人、好友一同共享橘色時光<br>現在就立即線上訂位"
          ]
        },
        'en': {
          slogan: [
            "<span>Share</span><span> the </span><span>Feast</span>", "Enjoy a special time at Orange with your beloved family and friends"
          ]
        }
      }
    }
  },
  computed: {
    ...setComputed,
    textModule() {
      return this.currentLanguage === 'zh-TW'?  this.text['zh-TW']:this.text['en']
    },
    getBookingIframeUrl() {
      const url = "https://inline.app/booking/-LL7w1Y0HNb5dvdA1v7N:inline-live-2a466"
      return this.currentLanguage === 'zh-TW'? url:url+"/?language=en"
    }
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll, { passive: false });
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll, { passive: false });
    setTimeout(()=> {
      this.animateStart = true
    }, 600)
  },
  methods: {
    onScroll() {
      if (this.scrollEnd) {
        clearTimeout(this.scrollEnd);
      }

      this.scrollEnd = setTimeout(() => {
        this.supportScroll();
      }, 200);
    },
    supportScroll() {
      const scrollTop = this.getScrollTop();
      const blockOffsetTop = document.querySelector(`.${this.$style.sectionBooking}`).offsetTop;

      if(Math.abs(scrollTop - (blockOffsetTop - 92)) < 100) {
        this.scrollTo(this.$style.sectionBooking, - 92)
      }
    },
    getScrollTop,
    scrollTo
  },
}
</script>

<template>
  <Layout>
    <section :class="[$style.sectionBanner, { [$style.isAnimateStart]: animateStart }]">
      <h2 :class="[$style.title, { [$style['write-horizontal']]: this.currentLanguage !== 'zh-TW' }]" v-html="textModule.slogan[0]"></h2>
      <p :class="$style.content" v-html="textModule.slogan[1]"></p>
    </section>
    <section :class="$style.sectionBooking">
      <div class="container">
     <!--   <iframe
          :class="$style.bookingIframe"
          src="https://inline.app/booking/-LL7w1Y0HNb5dvdA1v7N:inline-live-2a466"></iframe>-->
        <div :class="$style.bookingIframeBox">
          <iframe
            :class="$style.bookingIframe"
            :src="getBookingIframeUrl"></iframe>
          <div :class="$style.loading">
            <img src="@assets/images/booking/inline_logo.png">
            <font-awesome-icon
              :class="$style.icon"
              class="fa-spin"
              :icon="['fal', 'circle-notch']"></font-awesome-icon>
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>
<style lang="scss" module>
  $banner-height: 782px;
  .sectionBanner {
    background-image: url('@assets/images/booking/booking_banner.jpg');
    // height: 100vh;
    // height: calc(100vh - 130px);
    background-position: center;
    color: #ece8de;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    height: $banner-height;
    @include touch {
      height: calc(100vh - 70px);
      background-position: top;
    }
    &:before {
      content: "";
      height: 200px;
      width: 100%;
      position: absolute;
      left: 0;
      transform: translateY(-50%);
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), #000000);
      z-index: -1;
      // top: calc((100vh - 610px) / 2);
      top: 150px;
      @include touch {
        top: 0%;
        transform: translateY(0);
      }
    }
    .title {
      writing-mode: vertical-lr;
      font-size: 48px;
      letter-spacing: 10px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      // top: calc((100vh - 130px) / 2);
      top: calc(#{$banner-height} / 2);
      margin: 0;
      margin-top: -181px;
      @include touch {
        top: 75px;
        margin-top: 0;
        transform: translateX(-50%);
      }
    }
    .content {
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 1px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      // top: calc((100vh - 130px) / 2);
      top: calc(#{$banner-height} / 2);
      margin: 0;
      margin-top: 20px;
      text-align: center;
      font-weight: 500;
      @include touch {
        top: 345px;
        margin-top: 0;
        transform: translateX(-50%);
        width: 100%;
      }
    }
    &.isAnimateStart {
      .title {
        span {
          opacity: 1;
        }
      }
      .content {
        opacity: 1;
      }
    }
    .title {
      &.write-horizontal {
        writing-mode: horizontal-tb !important;
      }
      span {
        opacity: 0;
        &:nth-child(1) {
          transition: opacity 2.1s linear 0.3s;
        }
        &:nth-child(2) {
          transition: opacity 2.1s linear 0.6s;
        }
        &:nth-child(3) {
          transition: opacity 2.1s linear 0.9s;
        }
      }
    }
    .content {
      transition: opacity 2.1s linear 1.8s;
      opacity: 0;
    }
  }
  .sectionBooking {
    padding: 0rem 0 24px;
    background-image: url('@assets/images/booking/booking_bg.jpg');
    background-size: cover;
    background-position: center 3rem;
    position: relative;
    z-index: 1;
    margin-top: -5.5rem;
    position: relative;
    pointer-events: none;
    background-color: transparent;
    background-repeat: no-repeat;
    @include touch {
      padding-top: 0;
      margin-top: -3rem;
    }
    /*.bookingIframe {
      width: 100%;
      height: 100vh;
      height: calc(100vh - 92px);
      border: 0px solid transparent;
      pointer-events: auto;
      @include touch {
        height: 90vh;
        height: calc(100vh - 70px);
      }
    }*/
    .bookingIframeBox {
      background: #fff;
      position: relative;
      z-index: 0;
      .bookingIframe {
        width: 100%;
        height: 100vh;
        height: calc(100vh - 92px);
        border: 0px solid transparent;
        pointer-events: auto;
        @include touch {
          height: 90vh;
          height: calc(100vh - 70px);
        }
      }
      .loading {
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        .icon {
          color: #ff5722;
          font-size: 150px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -75px;
          margin-top: -75px;
          stroke: #fff;
          opacity: 0.75;
          stroke-width: 25px;
        }
        img {
          max-width: 80%;
          margin: auto;
        }
      }
    }
  }
</style>
